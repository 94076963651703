import * as React from 'react'
import { useEffect, useState } from "react";
import { RichText } from 'prismic-reactjs'
import { graphql } from "gatsby";

import Head from "../components/Head.js";
import Layout from "../components/Layout.js";
import createLink from "../components/functions/createLink.js";

import "../styles/css/404.css"

const P404 = ({ doc, lang }) => {

  const setLink = (link) => createLink(link, lang);

  let mount = true;
  useEffect(() => {}, [mount]);

  return (
    <>
      <section className='section-404'>
        <img class="obj-1" src="/img/404/obj-1.svg" alt="" />
        <img class="obj-2" src="/img/404/obj-2.svg" alt="" />
        <div className="wrapper">
          <div className="container">
            <h1>{RichText.asText(doc.hero_title)}</h1>
            <div className="container-text">
              <p>{RichText.asText(doc.hero_text)}</p>
              <div className="container-btn">
                <a href={setLink(doc.hero_btnlnk)} className='btn'>
                  <div className="btn-text">
                    {RichText.asText(doc.hero_btntxt)}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
};

const InitP404 = ({ data }) => {
  const lang = data.prismicP404.lang.substring(0, 2);
  const header = JSON.parse(data.prismicHeader.dataString);
  const categories = data.allPrismicCategories.edges;
  const articles = data.allPrismicArticle.edges;
  const footer = JSON.parse(data.prismicFooter.dataString);
  const doc = JSON.parse(data.prismicP404.dataString);

  const [focus, setFocus] = useState(null);

  if (!doc || !header || !footer) return null;

  return (
    <>
      <Head doc={doc} lang={lang} />
      <div className="404">
        <Layout
          focus={focus}
          setFocus={setFocus}
          header={header}
          articles={articles}
          cat={categories}
          footer={footer}
          lang={lang}
          type={"404"}
        >
          <main>
            <P404 doc={doc} lang={lang} />
          </main>
        </Layout>
      </div>
    </>
  );
};

export default InitP404;

export const query = graphql`
query callP404($lang: String) {
prismicHeader(lang: { eq : $lang }) {
  dataString
}
allPrismicCategories(filter: {lang: {eq: $lang}}) {
  edges {
      node {
          slugs
          dataString
      }
  }
}
allPrismicArticle(filter: {lang: {eq: $lang}}) {
  edges {
      node {
          dataString
          first_publication_date
      }
  }
}
prismicP404(lang: {eq: $lang}) {
    dataString
    lang
    uid
}
prismicFooter(lang: {eq: $lang}) {
  dataString
}
allPrismicFocus(filter: {lang: {eq: $lang}}) {
  edges {
      node {
          prismicId
          dataString
          first_publication_date
          uid
      }
  }
}
}
`;